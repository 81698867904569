import * as React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Tab, Tabs, Hidden } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ScheduleIcon from "@material-ui/icons/Schedule";

import HotelIcon from "@material-ui/icons/Hotel";
import InfoIcon from "@material-ui/icons/Info";
import PhoneIcon from "@material-ui/icons/Phone";
import AssignmentIcon from "@material-ui/icons/Assignment";
export interface CompProps {
	onTabChanged(idx: number): void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appbar: {
			backgroundColor: "rgba( 73, 66, 54, 0.7)", // primary color with opacity 0.7
			boxShadow: "none",
			width: "100%",
		},
		toolbar: {
			minHeight: 0,
		},
		tabs: {
			marginRight: "auto",
			marginLeft: "auto",
		},
		tab: {
			backgroundClip: "text",
			backgroundColor: "transparent",
			overlayStyle: "transparent",
			color: "#FFFFFF",
			fontWeight: "bold",
			minHeight: "48px",
			paddingLeft: 0,
			paddingRight: 0,
		},
	})
);

export function AppNavigation(props: CompProps) {
	const { onTabChanged } = props;
	const classes = useStyles(props);
	const [selectedTab, setSelectedTab] = React.useState(0);

	const handleChange = (evt: React.ChangeEvent<{}>, idx: number) => {
		setSelectedTab(idx);
		onTabChanged(idx);
	};
	return (
		<AppBar position="fixed" className={classes.appbar}>
			<Toolbar className={classes.toolbar}>
				<Tabs value={selectedTab} onChange={handleChange} variant="scrollable" className={classes.tabs}>
					<Tab
						className={classes.tab}
						label={<Hidden smDown>Ablauf</Hidden>}
						icon={
							<Hidden mdUp>
								<ScheduleIcon />
							</Hidden>
						}
					/>
					<Tab
						className={classes.tab}
						label={<Hidden smDown>Übernachtung</Hidden>}
						icon={
							<Hidden mdUp>
								<HotelIcon />
							</Hidden>
						}
					/>
					<Tab
						className={classes.tab}
						label={<Hidden smDown>Wichtige Infos</Hidden>}
						icon={
							<Hidden mdUp>
								<InfoIcon />
							</Hidden>
						}
					/>
					<Tab
						className={classes.tab}
						label={<Hidden smDown>Kontakt</Hidden>}
						icon={
							<Hidden mdUp>
								<PhoneIcon />
							</Hidden>
						}
					/>
					<Tab
						className={classes.tab}
						label={<Hidden smDown>Anmeldung</Hidden>}
						icon={
							<Hidden mdUp>
								<AssignmentIcon />
							</Hidden>
						}
					/>
					/>
				</Tabs>
			</Toolbar>
		</AppBar>
	);
}
