import * as React from "react";
import { Grid, Card, CardMedia, Typography, CardContent, Link } from "@material-ui/core";
import imageSchedule from "./kirche.jpg";

export interface CompProps {
	reference: React.RefObject<unknown | number>;
	compClasses: Record<"cardContainer" | "cardMedia" | "cardMediaSmall" | "cardTitle" | "cardContent", string>;
}

export function Schedule(props: CompProps) {
	const { reference, compClasses } = props;

	return (
		<Grid item xs={12} sm={8}>
			<Card ref={reference}>
				<CardMedia image={imageSchedule} title={"Church_Belsen"} className={compClasses.cardMediaSmall}>
					<Typography variant={"h5"} className={compClasses.cardTitle}>
						Ablauf
					</Typography>
				</CardMedia>
				<CardContent className={compClasses.cardContent}>
					<div>
						Wir heiraten am 26.09.2020!
						<p />
						Um 14 Uhr beginnt der Traugottesdienst in der{" "}
						<Link href={"http://trauung.sandrabenni.de"} underline="always">
							Evangelischen Kirche in Belsen
						</Link>
						. Danach wollen wir beim Sektempfang mit euch anstoßen. Bei schönem Wetter machen wir das direkt vor der
						Kirche, bei schlechtem Wetter im{" "}
						<Link href={"https://goo.gl/maps/4KQhJaAhpxPP4Fvc7"} underline="always">
							Gemeindehaus in Belsen
						</Link>
						. Im Anschluss fahren wir gegen 16:15 Uhr gemeinsam in Kolonne zum{" "}
						<Link href={"http://feier.sandrabenni.de"} underline="always">
							Alb-Stadl bei Engstingen
						</Link>{" "}
						und lassen dort die Party steigen.
					</div>
				</CardContent>
			</Card>
		</Grid>
	);
}
