import * as React from "react";
import {
	Grid,
	Card,
	CardMedia,
	Typography,
	CardContent,
	Button,
	Fab,
	Divider,
	createStyles,
	Theme,
	makeStyles,
} from "@material-ui/core";
import imageRegistration from "./welcome.jpg";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { RegistrationForm } from "./registration-form";
import { SendMail } from "../../email/email";

export interface CompProps {
	reference: React.RefObject<unknown | number>;
	compClasses: Record<"cardContainer" | "cardMedia" | "cardMediaSmall" | "cardTitle" | "cardContent", string>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		errorMessage: {
			color: "#FF0000",
		},
	})
);

interface Input {
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	veggie: boolean;
	allergy: string | undefined;
}

export function Registration(props: CompProps) {
	const { reference, compClasses } = props;
	const classes = useStyles(props);
	const [registrationFormNumber, setRegistrationFormNumber] = React.useState<number>(1);
	const [submitted, setSubmitted] = React.useState<boolean>(false);
	const [emailContent, setEmailContent] = React.useState<Input[]>([]);
	const [validation, setValidation] = React.useState<boolean[][]>([]);
	const [hasError, setHasError] = React.useState(false);

	const handlePersonAdd = () => {
		setRegistrationFormNumber(registrationFormNumber + 1);
		setEmailContent(emailContent.concat([]));
	};

	const handlePersonRemove = () => {
		setRegistrationFormNumber(registrationFormNumber - 1);
		setEmailContent(emailContent.splice(registrationFormNumber - 1));
	};

	const handleSubmit = () => {
		const setByField = getSetByField();
		const notSet = setByField.map((fields) => fields.find((field) => field === false)).find((field) => field === false);
		if (notSet === undefined) {
			const joinedContent = emailContent.map((entry) => JSON.stringify(entry)).join("\n\n");
			const response = SendMail(joinedContent);
			if (response) {
				setSubmitted(true);
			}
		} else {
			setValidation(setByField);
			setHasError(true);
		}
	};

	const getSetByField = () => {
		return emailContent.map((entry, index) => {
			const hasFirstName = entry.firstName !== null;
			const hasLastName = entry.lastName !== null;
			let hasEmailIfNeeded = true;
			if (index === 0) {
				hasEmailIfNeeded = entry.email !== null;
			}
			return [hasFirstName, hasLastName, hasEmailIfNeeded];
		});
	};

	const handleInputChanged = (
		input: {
			firstName: string | null;
			lastName: string | null;
			email: string | null;
			veggie: boolean;
			allergy: string | undefined;
		},
		index: number
	) => {
		const updatedEmailContent = emailContent;
		updatedEmailContent[index] = input;
		setEmailContent(updatedEmailContent);
	};

	return (
		<Grid item xs={12} sm={8}>
			<Card ref={reference}>
				<CardMedia image={imageRegistration} title={"Anmeldung"} className={compClasses.cardMediaSmall}>
					<Typography variant={"h5"} className={compClasses.cardTitle}>
						Anmeldung
					</Typography>
				</CardMedia>
				<CardContent className={compClasses.cardContent}>
					{submitted ? (
						<Grid>Vielen Dank für die Anmeldung, die Daten reisen jetzt mit Lichtgeschwindigkeit zum Brautpaar.</Grid>
					) : (
						<Grid>
							<Grid item xs={12}>
								Wir freuen uns, wenn ihr kommt! Bitte meldet euch bis spätestens 30. Juni an, damit wir besser planen
								können.
							</Grid>
							{Array.from(Array(registrationFormNumber)).map((_, index) => (
								<Grid item xs={12} key={index}>
									{registrationFormNumber !== 1 && index === registrationFormNumber - 1 && (
										<Button
											color="primary"
											aria-label="deleteForeverIcon"
											style={{
												float: "right",
												minWidth: 0,
											}}
											onClick={handlePersonRemove}
										>
											<HighlightOffIcon />
										</Button>
									)}
									<RegistrationForm
										key={index}
										index={index}
										onInputChanged={handleInputChanged}
										validation={validation[index]}
									/>
									<Divider light />
								</Grid>
							))}
							<Grid item xs={12}>
								<Fab
									color="primary"
									aria-label="personAdd"
									onClick={handlePersonAdd}
									style={{ float: "right", marginTop: "16px" }}
									size="small"
								>
									<PersonAddIcon />
								</Fab>
							</Grid>
							<Grid container spacing={0} justify="center">
								<Grid item xs={12} sm={6} style={{ paddingTop: "80px" }}>
									<Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
										{registrationFormNumber === 1 && "Ja, ich komme!"}
										{registrationFormNumber > 1 && "Ja, wir kommen!"}
									</Button>
								</Grid>
							</Grid>
							{hasError && (
								<p className={classes.errorMessage}>
									Ups! Das Formular konnte nicht abgeschickt werden, weil nicht alle Pflichtfelder ausgefüllt sind.
									Bitte fülle alle Pflichtfelder aus und schicke das Formular erneut ab.
								</p>
							)}
						</Grid>
					)}
				</CardContent>
			</Card>
		</Grid>
	);
}
