import * as React from "react";
import { Card, Grid, CardMedia, Link } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import imageMain from "../content/we.jpg";
import { Registration } from "./registration/registration";
import { Organization } from "./organization/organization";
import { Accommodation } from "./accommodation/accommodation";
import { Schedule } from "./schedule/schedule";
import { Contact } from "./contact/contact";

export interface CompProps {
	selectedTab: number | null;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cardContainer: {
			paddingTop: 96,
			backgroundColor: "#E0CFB8",
			paddingBottom: 96,
		},
		cardMedia: {
			height: 800,
		},
		cardMediaSmall: {
			height: 200,
		},
		cardTitle: {
			color: theme.palette.secondary.main,
			paddingTop: 152,
			paddingLeft: 20,
		},
		cardContent: {
			color: "#494236",
		},
		banner: {
			paddingTop: 24,
			backgroundColor: "#E0CFB8",
		},
		bannerText: {
			padding: 16,
		},
	})
);

export function Content(props: CompProps) {
	const { selectedTab } = props;
	const classes = useStyles(props);
	React.useEffect(() => {
		if (selectedTab !== null) {
			handleClick(selectedTab);
		}
	});

	const refSchedule = React.createRef<number | unknown>();
	const refAccommodation = React.createRef<number | unknown>();
	const refOrganization = React.createRef<number | unknown>();
	const refContact = React.createRef<number | unknown>();
	const refRegistration = React.createRef<number | unknown>();

	const handleClick = (id: number) => {
		const card = matchRef(id);
		if (card) {
			(card.current as HTMLElement).scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};
	const matchRef = (id: number) => {
		switch (id) {
			case 0:
				return refSchedule;
			case 1:
				return refAccommodation;
			case 2:
				return refOrganization;
			case 3:
				return refContact;
			case 4:
				return refRegistration;
			default:
				return;
		}
	};

	return (
		<>
			<Grid container direction={"column"}>
				<Grid item xs={12}>
					<Card square>
						<CardMedia className={classes.cardMedia} image={imageMain} title={"Sandra_and_Benni_in_Colombia"} />
					</Card>
				</Grid>
			</Grid>
			<Grid container direction={"column"} className={classes.banner}>
				<Card square>
					<Grid container justify={"center"}>
						<Grid item xs={12} sm={8} className={classes.bannerText}>
							<div>
								+++ Update 02.09.2020 +++
								<h3 id="hallo-ihr-lieben-">Hallo ihr Lieben,</h3>
								<p>
									trotz der momentanen Ungewissheit freuen wir uns schon total auf euch und unsere Hochzeit. Wir haben
									uns entschieden die Hochzeit, sofern möglich, trotz des Risikos nicht zu verschieben. Einerseits
									wissen wir nicht ob es nächstes Jahr besser sein wird und andererseits denken wir auch an die Personen
									deren Existenz an den Hochzeitsfeiern hängt also Location, Fotograf, Band, usw.. Das bedeutet für
									euch: <strong>Falls ihr nichts anderes von uns hört, findet die Hochzeit statt.</strong>
								</p>
								<h3 id="corona-infos">Corona-Infos</h3>
								<p>
									Wegen des Virus müssen wir natürlich gewisse Regeln befolgen. Die größten Einschränkungen gibt es bei
									der Kirche, aufgrund der Abstandsregelung findet der <strong>Gottesdienst draussen</strong> hinter der
									Kirche statt da die Kirche zu klein ist. Bei schlechtem Wetter haben wir eine Alternative, worüber wir
									euch im Fall der Fälle wenige Tage vor der Hochzeit informieren.{" "}
									<strong>Ruft deshalb bitte unbedingt vor der Hochzeit eure E-Mails nochmal ab!</strong> Des Weiteren
									gilt für den Gottesdienst folgendes:
								</p>
								<ul>
									<li>
										Personen die weder verwandt oder Partner sind noch zum selben Haushalt gehören müssen beim Sitzen 2m
										Abstand einhalten.
									</li>
									<li>
										Beim Singen muss ein Mund-Nasen-Schutz getragen werden.{" "}
										<strong>Bitte bringt also einen Mund-Nasen-Schutz mit.</strong>
									</li>
								</ul>
								<p>Generell könnt ihr Folgendes tun um das Risiko für alle zu verringern:</p>
								<ul>
									<li>Die Tage vor der Hochzeit die Zahl der Kontakte möglichst gering halten</li>
									<li>Die Corona-Warn-App verwenden</li>
									<li>Abmelden falls ihr euch am Tag der Hochzeit krank fühlt</li>
								</ul>
								<p>
									Beim Gratulieren könnt ihr ganz klassisch vorgehen und uns natürlich gerne umarmen oder die Hand
									schütteln, aber wenn es euch kontaktlos lieber ist seid einfach kreativ, wir sind vom Namaste-Gruß bis
									zum mit dem Sektglas Anstoßen für alles zu haben! ;)
								</p>
								<p>
									In der Location müsst ihr am Tisch natürlich keinen Mund-Nasen-Schutz tragen, ebensowenig auf der
									Tanzfläche. Beim Bewegen in den Gängen, z.B. auf dem Weg zur Toilette und am Buffett, ist mit der
									Location abgemacht dass ein Mund-Nasen-Schutz getragen wird, auch zum Schutz des Personals. Außerdem
									wird die Luftumwälzung dort abgeschaltet, stattdessen werden wir regelmäßig lüften. Auf den Toiletten
									wird es auch keine Notfallkörbchen geben, falls ihr etwas braucht müsst ihr es selber mitbringen!
								</p>
								<p>
									Falls euch jetzt total unwohl bei dem Gedanken an eine Feier mit 90 Personen ist und es euch ein zu
									hohes Risiko ist, fänden wir es zwar mega schade aber hätten dafür auch Verständnis. Meldet euch in
									dem Fall aber bitte unbedingt bis spätestens nächsten Mittwoch, 09.09. ab, da wir bis dahin die finale
									Gästeliste brauchen.
								</p>
								<h3 id="anreise">Anreise</h3>
								<ul>
									<li>
										Für Fahrgemeinschaften könnt ihr euch hier eintragen:{" "}
										<Link
											href="https://docs.google.com/spreadsheets/d/1mL-uYKX9_jqNAHj_jXuIsx5yP18Dign4d3TZ8CoEBSs/edit?usp=sharing"
											underline="always"
										>
											https://docs.google.com/spreadsheets/d/1mL-uYKX9_jqNAHj_jXuIsx5yP18Dign4d3TZ8CoEBSs/edit?usp=sharing
										</Link>
										<div>Bitte organisiert das unter euch.</div>
									</li>
									<li>
										Bedenkt die Fahrtzeit vom Hotel zur Kirche. Beim Gästehaus Hirlinger ist Check-In ab 12 Uhr (in
										Einzelfällen sogar ab 11 Uhr) problemlos möglich. Wenn ihr in Speidel&#39;s Braumanufaktur
										übernachtet meldet euch bitte nochmal kurz (z.B. per WhatsApp) bei uns, hier ist noch offen ob das
										mit dem frühen Check-In klappt.
									</li>
								</ul>
								<h3 id="ablauf">Ablauf</h3>
								<ul>
									<li>
										14:00 Uhr Trauung (
										<Link href="http://trauung.sandrabenni.de" underline="always">
											http://trauung.sandrabenni.de
										</Link>
										), bitte seid bereits etwas früher da
									</li>
									<li>Anschließend Sektempfang </li>
									<li>16:15 Gruppenfoto</li>
									<li>
										Anschließend Autokorso zum Alb-Stadl (
										<Link href="http://feier.sandrabenni.de" underline="always">
											http://feier.sandrabenni.de
										</Link>
										)
									</li>
									<li>
										Ab 0:00 Uhr gibt es ein Heimfahrtaxi, ihr könnt die Autos über Nacht einfach auf dem Parkplatz vor
										dem Alb-Stadl stehen lassen.
									</li>
								</ul>
								<h3 id="google-maps-links">Google Maps Links</h3>
								<ul>
									<li>
										Gästehaus Hirlinger:{" "}
										<Link href="https://goo.gl/maps/Phxy7kYc4WjDZrZF8" underline="always">
											https://goo.gl/maps/Phxy7kYc4WjDZrZF8
										</Link>
									</li>
									<li>
										Speidel&#39;s Braumanufaktur:{" "}
										<Link href="https://goo.gl/maps/fBZVZkdniDyvHRSYA" underline="always">
											https://goo.gl/maps/fBZVZkdniDyvHRSYA
										</Link>
									</li>
									<li>
										Kirche:{" "}
										<Link href="http://trauung.sandrabenni.de" underline="always">
											http://trauung.sandrabenni.de
										</Link>
									</li>
									<li>
										Feier:{" "}
										<Link href="http://feier.sandrabenni.de" underline="always">
											http://feier.sandrabenni.de
										</Link>
									</li>
								</ul>
								<h3 id="tl-dr">tl;dr</h3>
								<p>Hochzeit findet statt, Mundschutz mitbringen, Freitag E-mails nochmal lesen</p>
								<p>
									<strong>
										Liebe Grüße und bis bald,
										<br />
										Sandra und Benni
									</strong>
								</p>
							</div>
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<Grid container justify={"center"} spacing={8} direction={"row"} className={classes.cardContainer}>
				<Schedule reference={refSchedule} compClasses={classes} />
				<Accommodation reference={refAccommodation} compClasses={classes} />
				<Organization reference={refOrganization} compClasses={classes} />
				<Contact reference={refContact} compClasses={classes} />
				<Registration reference={refRegistration} compClasses={classes} />
			</Grid>
		</>
	);
}
