import * as React from "react";
import { Grid, Card, CardMedia, Typography, CardContent, Link } from "@material-ui/core";
import imageContact from "./contact.jpg";
import { ContactInfo } from "./contactInfo";

export interface CompProps {
	reference: React.RefObject<unknown | number>;
	compClasses: Record<"cardContainer" | "cardMedia" | "cardMediaSmall" | "cardTitle" | "cardContent", string>;
}

export function Contact(props: CompProps) {
	const { reference, compClasses } = props;
	const [contacts, setContacts] = React.useState<{
		wir: string;
		dani: string;
		tiffy: string;
	}>({ wir: "", dani: "", tiffy: "" });
	React.useEffect(() => {
		ContactInfo().then((resp) => {
			setContacts(resp);
		});
	}, []);

	return (
		<Grid item xs={12} sm={8}>
			<Card ref={reference}>
				<CardMedia image={imageContact} title={"Kontakt"} className={compClasses.cardMediaSmall}>
					<Typography variant={"h5"} className={compClasses.cardTitle}>
						Kontakt
					</Typography>
				</CardMedia>
				<CardContent className={compClasses.cardContent}>
					<>
						<p>Bei Fragen wendet euch gerne an unsere Trauzeugen</p>
						<div>
							<Link href="mailto:trauzeugen@sandrabenni.de" underline="always">
								trauzeugen@sandrabenni.de
							</Link>
						</div>
						<div>
							Stephanie Brunner{" "}
							<Link href={`tel:${contacts.tiffy}`} underline="always">
								{contacts.tiffy}
							</Link>
						</div>
						<div>
							Daniel Wassermann{" "}
							<Link href={`tel:${contacts.dani}`} underline="always">
								{contacts.dani}
							</Link>
						</div>
						<p>oder direkt an uns</p>
						<div>
							<Link href="mailto:hochzeit@sandrabenni.de" underline="always">
								hochzeit@sandrabenni.de
							</Link>
						</div>
						<div>
							<Link href={`tel:${contacts.wir}`} underline="always">
								{contacts.wir}
							</Link>
						</div>
						<p>Wir freuen uns auf euch!</p>
					</>
				</CardContent>
			</Card>
		</Grid>
	);
}
