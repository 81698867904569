export interface ContactResponse {
	wir: string;
	dani: string;
	tiffy: string;
}

export async function ContactInfo() {
	const requestOptions = {
		method: "GET",
		headers: {
			Accept: "application/json",
			Authorization: "Basic " + btoa(`supersecretusername:${localStorage.getItem("password")}`),
		},
	};

	function handleResponse(response: Response): Promise<any> {
		if (response.status !== 200) {
			throw new Error("Send form data failed with status " + response.statusText + ".");
		}
		return response.json();
	}

	return await fetch(`https://sandrabenni.de/secure/contacts.php`, requestOptions).then(handleResponse);
}
