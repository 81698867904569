export async function SendMail(emailContent: string) {
	const formData = new FormData();
	formData.append("content", emailContent);
	const requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			Authorization: "Basic " + btoa(`supersecretusername:${localStorage.getItem("password")}`),
		},
		body: formData,
	};

	const handleResponse = (response: any) => {
		if (response.status !== 200) {
			throw new Error("Send form data failed with status " + response.statusText + ".");
		} else {
			return true;
		}
	};

	return fetch(`https://sandrabenni.de/secure/sendmail.php`, requestOptions)
		.then(handleResponse)
		.then((response) => {
			return response;
		});
}
