import * as React from "react";
import { Grid, Card, CardMedia, Typography, CardContent } from "@material-ui/core";
import imageOrganization from "./albstadl.jpg";

export interface CompProps {
	reference: React.RefObject<unknown | number>;
	compClasses: Record<"cardContainer" | "cardMedia" | "cardMediaSmall" | "cardTitle" | "cardContent", string>;
}

export function Organization(props: CompProps) {
	const { reference, compClasses } = props;
	return (
		<Grid item xs={12} sm={8}>
			<Card ref={reference}>
				<CardMedia image={imageOrganization} title={"Wichtige Infos"} className={compClasses.cardMediaSmall}>
					<Typography variant={"h5"} className={compClasses.cardTitle}>
						Wichtige Infos
					</Typography>
				</CardMedia>
				<CardContent className={compClasses.cardContent}>
					<div>
						Falls ihr uns etwas schenken möchtet freuen wir uns am meisten über einen Beitrag zu unserer Hochzeitsreise.
						Wenn ihr eine Einlage oder Überraschung plant sprecht das bitte vorher mit unseren Trauzeugen ab. Aus
						Brandschutzgründen dürfen im Alb-Stadl keine leicht entflammbaren Gegenstände wie Wunderkerzen verwendet
						werden. Während dem Gottesdienst bitte nicht fotografieren und in bzw. vor der Kirche darf kein Konfetti
						o.Ä. geworfen werden (wobei wir nicht denken dass überhaupt jemand auf die Idee gekommen wäre ;) )
					</div>
				</CardContent>
			</Card>
		</Grid>
	);
}
