import * as React from "react";
import { Grid, Card, CardMedia, Typography, CardContent, Link } from "@material-ui/core";
import imageAccommodation from "./sleep.jpg";

export interface CompProps {
	reference: React.RefObject<unknown | number>;
	compClasses: Record<"cardContainer" | "cardMedia" | "cardMediaSmall" | "cardTitle" | "cardContent", string>;
}

export function Accommodation(props: CompProps) {
	const { reference, compClasses } = props;
	return (
		<Grid item xs={12} sm={8}>
			<Card ref={reference}>
				<CardMedia
					image={imageAccommodation}
					title={"Übernachtungsmöglichkeiten"}
					className={compClasses.cardMediaSmall}
				>
					<Typography variant={"h5"} className={compClasses.cardTitle}>
						Übernachtungsmöglichkeiten
					</Typography>
				</CardMedia>
				<CardContent className={compClasses.cardContent}>
					<div>
						Falls ihr eine Übernachtungsmöglichkeit braucht, bitten wir euch, dass ihr euch selbst um die Buchung
						kümmert. Bei folgenden Häusern haben wir für euch angefragt und werden euch nachts einen Fahrdienst dorthin
						zurück anbieten. Sollte bei eurer Buchung eines der Häuser bereits ausgebucht sein, gebt uns bitte Bescheid,
						damit wir die Infos aktualisieren können.
						<br />
						<br />
						<p>
							<b>
								<Link href="http://www.gaestehaus-hirlinger.de/" underline="always">
									Gästehaus Hirlinger
								</Link>
							</b>
						</p>
						<p>
							Das familiengeführte Gästehaus Hirlinger liegt etwa 15 Autominuten von der Feier-Location entfernt und
							bietet Einzel-, Doppel- und Dreibettzimmer sowie Apartments an. Bitte erwähnt bei der Buchung zwecks
							Zuordnung, dass ihr Gäste bei unserer Hochzeit seid.
							<br />
							Preis für ein Doppelzimmer inkl. Frühstück: <b>85-105€</b> (bei der Buchung wird eine Anzahlung fällig)
						</p>
						<br />
						<b>
							<Link href="https://www.speidels-braumanufaktur.de/" underline="always">
								Speidel's Braumanufaktur
							</Link>
						</b>
						<p>
							Das 4*-Hotel Speidel's Braumanufaktur mit seinen modernen Zimmern liegt etwa 10 Autominuten von der
							Feier-Location entfernt. Wer Lust hat, kann sich durch die hauseigenen Bierspezialitäten probieren.
							<br />
							Preis für ein Doppelzimmer inkl. Bierbrauer-Frühstücksbuffet: <b>ca. 140€</b>
						</p>
					</div>
				</CardContent>
			</Card>
		</Grid>
	);
}
