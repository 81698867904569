import React, { useState } from "react";

export const AuthContainer: React.FC = (props) => {
	const [loggedIn, setLoggedIn] = useState<boolean>(false);
	const [loginError, setLoginError] = useState<string | undefined>(undefined);

	const checkAuth = (password: string | null) => {
		setLoginError(undefined);
		const username = "supersecretusername";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ user: username, password }),
		};
		return fetch(`https://sandrabenni.de/authenticate.php`, requestOptions)
			.then(handleResponse)
			.then((response) => {
				if (response) {
					localStorage.setItem("password", password || "");
					setLoggedIn(true);
				} else {
					setLoginError("Passwort falsch.");
				}
			});
	};

	const handleResponse = (response: any) => {
		return response.status === 200;
	};

	return <AuthContext.Provider value={{ loggedIn, loginError, checkAuth }}>{props.children}</AuthContext.Provider>;
};
/*
function logout() {
	// remove user from local storage to log user out
	localStorage.removeItem("user");
}
*/
export type AuthContextShape = {
	loggedIn: boolean;
	loginError: string | undefined;
	checkAuth: (password: string | null) => void;
};

export const AuthContext = React.createContext({} as AuthContextShape);
