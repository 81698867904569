import React, { useContext, useState } from "react";
import { AuthContext } from "./AuthContainer";
import OwnHomepage from "./own-homepage/own-homepage";
import { TextField, Button, Theme, makeStyles, createStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		outerContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			height: "100%",
			width: "100%",
			position: "fixed",
		},
		container: {
			flexWrap: "nowrap",
		},
		textField: {
			bottom: "8px",
		},
	})
);

export const Login: React.FC = (props) => {
	let { loggedIn, loginError, checkAuth } = useContext(AuthContext);
	const [inputValue, setInputValue] = useState<string | null>(null);
	const [showError, setShowError] = useState<boolean>(false);
	const classes = useStyles(props);
	const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(evt.target.value);
		setShowError(false);
	};
	React.useEffect(() => {
		setShowError(loginError !== undefined);
	}, [loginError]);

	const handleKeyDown = (evt: React.KeyboardEvent<HTMLDivElement>) => {
		if (evt.keyCode === 13) {
			checkAuth(inputValue);
		}
	};

	return loggedIn ? (
		<OwnHomepage />
	) : (
		<div className={classes.outerContainer}>
			<Grid container direction="column" alignItems="center" className={classes.container}>
				<Grid item xs={12} sm={4}>
					<TextField
						label="Password"
						type="password"
						autoComplete="current-password"
						variant="outlined"
						onChange={handleChange}
						onKeyDown={handleKeyDown}
						error={loginError !== undefined && showError}
						helperText={loginError && showError ? loginError : undefined}
						className={classes.textField}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Button
						color="primary"
						variant="contained"
						onClick={async () => {
							checkAuth(inputValue);
						}}
					>
						Login
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};
