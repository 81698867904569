import React from "react";
import { Login } from "./login";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";

const App: React.FC = () => {
	const theme = createMuiTheme({
		palette: {
			primary: { main: "#494236" },
			secondary: { main: "#FFFFFF" },
		},
	});
	return (
		<ThemeProvider theme={theme}>
			<Login />
		</ThemeProvider>
	);
};

export default App;
