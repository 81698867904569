import * as React from "react";
import { AppNavigation } from "../navigation/AppNavigation";
import { Content } from "../content/content";
import { Theme, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		app: {
			overflow: "hidden",
			width: "100%",
		},
	})
);

export default function OwnHomepage() {
	const classes = useStyles();
	const [selectedTab, setSelectedTab] = React.useState<number | null>(null);
	const handleTabSelected = (idx: number) => {
		setSelectedTab(idx);
	};
	return (
		<div className={classes.app}>
			<AppNavigation onTabChanged={handleTabSelected} />
			<Content selectedTab={selectedTab} />
		</div>
	);
}
