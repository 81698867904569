import * as React from "react";
import { TextField, FormControl, FormControlLabel, Grid, Checkbox } from "@material-ui/core";
import EcoIcon from "@material-ui/icons/Eco";
import EcoOutlinedIcon from "@material-ui/icons/EcoOutlined";
import { Theme, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		checkBox: {
			bottom: "-2px",
		},
	})
);

export interface CompProps {
	index: number;
	onInputChanged: (
		input: {
			firstName: string | null;
			lastName: string | null;
			email: string | null;
			veggie: boolean;
			allergy: string | undefined;
		},
		index: number
	) => void;
	validation: boolean[];
}

export function RegistrationForm(props: CompProps) {
	const { index, onInputChanged, validation } = props;
	const classes = useStyles(props);
	const [firstName, setFirstName] = React.useState<null | string>(null);
	const [lastName, setLastName] = React.useState<null | string>(null);
	const [email, setEmail] = React.useState<null | string>(null);
	const [veggie, setVeggie] = React.useState<boolean>(false);
	const [allergy, setAllergy] = React.useState<undefined | string>(undefined);
	React.useEffect(() => {
		onInputChanged({ firstName, lastName, email, veggie, allergy }, index);
	}, [firstName, lastName, email, veggie, allergy, onInputChanged, index]);

	return (
		<Grid container spacing={2} style={{ paddingTop: "16px", paddingBottom: "16px" }}>
			<Grid item xs={12} sm={4}>
				<FormControl fullWidth>
					<TextField
						label="Vorname"
						variant="outlined"
						required
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
							setFirstName(evt.target.value);
						}}
						error={validation && validation.length > 0 ? !validation[0] : false}
						helperText={validation && validation.length > 1 && !validation[1] && "Vorname fehlt"}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12} sm={4}>
				<FormControl fullWidth>
					<TextField
						label="Nachname"
						variant="outlined"
						required
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setLastName(evt.target.value)}
						error={validation && validation.length > 1 ? !validation[1] : false}
						helperText={validation && validation.length > 1 && !validation[1] && "Nachname fehlt"}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12} sm={4}>
				<FormControl fullWidth>
					{index === 0 && (
						<TextField
							label="Email"
							type="email"
							autoCapitalize="none"
							variant="outlined"
							required
							onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setEmail(evt.target.value)}
							error={validation && validation.length > 2 ? !validation[2] : false}
							helperText={validation && validation.length > 2 && !validation[2] && "Email fehlt"}
						/>
					)}
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={veggie}
							onChange={() => setVeggie(!veggie)}
							color="primary"
							className={classes.checkBox}
						/>
					}
					label={
						<>
							Vegetarier
							{veggie ? <EcoIcon viewBox="0 0 24 16" /> : <EcoOutlinedIcon viewBox="0 0 24 16" />}
						</>
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth>
					<TextField
						label="Allergien / Unverträglichkeiten"
						variant="outlined"
						helperText="Bitte gib hier an, wenn du z.B. eine Allergie / Unverträglichkeit auf bestimmte Lebensmittel hast"
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setAllergy(evt.target.value)}
					/>
				</FormControl>
			</Grid>
		</Grid>
	);
}
